import * as React from 'react';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className?: string;

    onClick(): void;
}

export default function Button({ children, className, onClick }: Props) {
    const classes = classNames({
        [className || '']: true,
        'py-2 bg-gray-300 text-gray-900': true
    });

    return (
        <button className={ classes } onClick={ onClick }>
            { children }
        </button>
    );
}
