import { createAction, createReducer } from '@reduxjs/toolkit';

export interface Scheme {
    author: string;
    background: string;
    color: string[];
    foreground: string;
    name: string;
}

export interface SchemeState {
    selectedColor: string;
    selectedExportFormat: string;
    selectedScheme: Scheme;
    selectedSchemeKey: string;
}

const initialState: SchemeState = {
    selectedColor: '#ffffff',
    selectedExportFormat: 'xresources',
    selectedScheme: {
        name: "",
        author: "",
        color: [
            "#151515",
            "#ac4142",
            "#90a959",
            "#f4bf75",
            "#6a9fb5",
            "#aa759f",
            "#75b5aa",
            "#d0d0d0",
            "#505050",
            "#ac4142",
            "#90a959",
            "#f4bf75",
            "#6a9fb5",
            "#aa759f",
            "#75b5aa",
            "#f5f5f5"
        ],
        foreground: "#d0d0d0",
        background: "#151515"
    },
    selectedSchemeKey: ''
}

interface SetSchemePayload {
    key: string;
    scheme: Scheme;
}

export const selectColor = createAction<string>('SelectColor');
export const selectExportFormat = createAction<string>('SelectExportFormat');
export const setScheme = createAction<SetSchemePayload>('SetScheme');

export default createReducer(initialState, builder => builder
    .addCase(selectColor, (state, action) => {
        return {
            ...state,
            selectedColor: action.payload
        }
    })
    .addCase(selectExportFormat, (state, action) => {
        return {
            ...state,
            selectedExportFormat: action.payload
        }
    })
    .addCase(setScheme, (state, action) => {
        return {
            ...state,
            selectedScheme: action.payload.scheme,
            selectedSchemeKey: action.payload.key
        }
    })
);
