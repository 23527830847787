import { Scheme } from '../schemeReducer';
import { hexToRgbCsv } from '../utils/colors';

export default {
    name: 'PuTTY',
    value: 'putty',
    extension: 'reg',
    formatter(scheme: Scheme): string {
        const background = hexToRgbCsv(scheme.background);
        const foreground = hexToRgbCsv(scheme.foreground);
        const colors = scheme.color.map(color => hexToRgbCsv(color));

        return `Windows Registry Editor Version 5.00 

[HKEY_CURRENT_USER\\Software\\SimonTatham\\PuTTY\\Sessions\\Default%20Settings]
"Colour0"="${ foreground }"
"Colour1"="${ foreground }"
"Colour2"="${ background }"
"Colour3"="${ background }"
"Colour4"="${ background }"
"Colour5"="${ foreground }"
"Colour6"="${ colors[0] }"
"Colour7"="${ colors[8] }"
"Colour8"="${ colors[1] }"
"Colour9"="${ colors[9] }"
"Colour10"="${ colors[2] }"
"Colour11"="${ colors[10] }"
"Colour12"="${ colors[3] }"
"Colour13"="${ colors[11] }"
"Colour14"="${ colors[4] }"
"Colour15"="${ colors[12] }"
"Colour16"="${ colors[5] }"
"Colour17"="${ colors[13] }"
"Colour18"="${ colors[6] }"
"Colour19"="${ colors[14] }"
"Colour20"="${ colors[7] }"
"Colour21"="${ colors[15] }"`
    }
}
