import { Scheme } from '../schemeReducer';

export default {
    name: 'Windows Terminal',
    value: 'windows-terminal',
    extension: 'json',
    formatter(scheme: Scheme): string {
        return `{
"name" : "${ scheme.name }",
"background": "${ scheme.background }",
"foreground": "${ scheme.foreground }",
"black": "${ scheme.color[0] }",
"brightBlack": "${ scheme.color[8] }",
"red": "${ scheme.color[1] }",
"brightRed": "${ scheme.color[9] }",
"green": "${ scheme.color[2] }",
"brightGreen": "${ scheme.color[10] }",
"yellow": "${ scheme.color[3] }",
"brightYellow": "${ scheme.color[11] }",
"blue": "${ scheme.color[4] }",
"brightBlue": "${ scheme.color[12] }",
"purple": "${ scheme.color[5] }",
"brightPurple": "${ scheme.color[13] }",
"cyan": "${ scheme.color[6] }",
"brightCyan": "${ scheme.color[14] }",
"white": "${ scheme.color[7] }",
"brightWhite": "${ scheme.color[15] }"
}
`
    }
}
