import * as React from 'react';
import { connect } from 'react-redux';
import Title from './Title';
import Section from './Section';
import { RootState } from './store';
import { Scheme } from './schemeReducer';

interface Props {
    scheme: Scheme;
}

function Example({ scheme }: Props) {
    return (
        <Section className="example h-1/2">
            <Title>Example</Title>

            <div className="example-output flex-grow border overflow-scroll p-4 mt-2 whitespace-pre" style={{ background: scheme.background, color: scheme.foreground }}>
                <div><span style={{ background: scheme.color[9], color: scheme.foreground }}> FAIL </span> src/example.test.js (<span style={{ background: scheme.color[1], color: scheme.color[7] }}>17.523s</span>)</div>
                <div><span style={{ color: scheme.color[1] }}>  ● should return the phrase from the Jest docs</span></div>
                <div>&nbsp;</div>
                <div>  expect(<span style={{ color: scheme.color[1] }}>received</span>).toBe(<span style={{ color: scheme.color[2] }}>expected</span>) // Object.is equality</div>
                <div>&nbsp;</div>
                <div>    Expected: <span style={{ color: scheme.color[2] }}>"Testing <span style={{ background: scheme.color[10], color: scheme.background }}>with Jest</span> is <span style={{ background: scheme.color[10], color: scheme.background }}>goo</span>d for you"</span></div>
                <div>    Received: <span style={{ color: scheme.color[1] }}>"Testing <span style={{ background: scheme.color[9], color: scheme.background }}>your luck</span> is <span style={{ background: scheme.color[9], color: scheme.background }}>ba</span>d for you"</span></div>
                <div>&nbsp;</div>
                <div>        219 | it(<span style={{ color: scheme.color[2] }}>'should return the phrase from the Jest docs'</span>, () <span style={{ color: scheme.color[3] }}>=></span> {'{'}</div>
                <div>      > 220 |   expect(theCorrectPhrase()).toBe(theWrongPhrase());</div>
                <div>        221 | });</div>
                <div>&nbsp;</div>
                <div>        at Object.toBe (<span style={{ color: scheme.color[6] }}>src/example.test.js</span>:220:61)</div>
                <div>&nbsp;</div>
                <div><span style={{ color: scheme.color[15] }}>Test Suites:</span> <span style={{ color: scheme.color[9] }}>1 failed</span>, <span style={{ color: scheme.color[10] }}>1 passed</span>, 2 total</div>
                <div><span style={{ color: scheme.color[15] }}>Tests:</span>       <span style={{ color: scheme.color[9] }}>1 failed</span>, <span style={{ color: scheme.color[11] }}>3 skipped</span>, <span style={{ color: scheme.color[10] }}>2 passed</span>, 6 total</div>
                <div><span style={{ color: scheme.color[15] }}>Time:</span>        <span style={{ color: scheme.color[11] }}>4.2s</span></div>
            </div>
        </Section>
    );
}

const mapStateToProps = (state: RootState) => ({
    scheme: state.scheme.selectedScheme
});

export default connect(mapStateToProps)(Example);
