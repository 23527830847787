import { Scheme } from '../schemeReducer';
import { hexToRgb } from '../utils/colors';

export default {
    name: 'LXTerminal',
    value: 'lxterminal',
    extension: 'conf',
    formatter(scheme: Scheme): string {
        function convertToRgb(hex: string) {
            const rgb = hexToRgb(hex);

            return `rgb(${ rgb.r },${ rgb.g },${ rgb.b })`;
        }

        const background = convertToRgb(scheme.background);
        const foreground = convertToRgb(scheme.foreground);
        const colors = scheme.color.map(color => convertToRgb(color));

        return `[general]
bgcolor=rgb(${ background })
fgcolor=rgb(${ foreground })
palette_color_0=rgb(${ colors[0] })
palette_color_1=rgb(${ colors[1] })
palette_color_2=rgb(${ colors[2] })
palette_color_3=rgb(${ colors[3] })
palette_color_4=rgb(${ colors[4] })
palette_color_5=rgb(${ colors[5] })
palette_color_6=rgb(${ colors[6] })
palette_color_7=rgb(${ colors[7] })
palette_color_8=rgb(${ colors[8] })
palette_color_9=rgb(${ colors[9] })
palette_color_10=rgb(${ colors[10] })
palette_color_11=rgb(${ colors[11] })
palette_color_12=rgb(${ colors[12] })
palette_color_13=rgb(${ colors[13] })
palette_color_14=rgb(${ colors[14] })
palette_color_15=rgb(${ colors[15] })`
    }
}
