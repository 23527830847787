import { Scheme } from '../schemeReducer';

export default {
    name: 'Terminalizer',
    value: 'terminalizer',
    extension: 'yaml',
    formatter(scheme: Scheme): string {
        return `theme:
  background: "${ scheme.background }"
  foreground: "${ scheme.foreground }"
  cursor: "${ scheme.foreground }"
  black: "${ scheme.color[0] }"
  red: "${ scheme.color[1] }"
  green: "${ scheme.color[2] }"
  yellow: "${ scheme.color[3] }"
  blue: "${ scheme.color[4] }"
  magenta: "${ scheme.color[5] }"
  cyan: "${ scheme.color[6] }"
  white: "${ scheme.color[7] }"
  brightBlack: "${ scheme.color[8] }"
  brightRed: "${ scheme.color[9] }"
  brightGreen: "${ scheme.color[10] }"
  brightYellow: "${ scheme.color[11] }"
  brightBlue: "${ scheme.color[12] }"
  brightMagenta: "${ scheme.color[13] }"
  brightCyan: "${ scheme.color[14] }"
  brightWhite: "${ scheme.color[15] }"`;
    }
}
