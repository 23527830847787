import * as React from 'react';
import Section from './Section';

function Brand() {
    return (
        <Section className="brand">
            <div className="font-bold text-base flex items-center">
                <span>
                    <svg className="inline mr-3 text-pink-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M18 1l-6 4-6-4-6 5v7l12 10 12-10v-7z"/>
                    </svg>
                </span>
                terminal.love
            </div>
        </Section>
    );
}

export default Brand;
