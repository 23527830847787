import { Scheme } from '../schemeReducer';

export default {
    name: 'Xresources',
    value: 'xresources',
    extension: 'txt',
    formatter(scheme: Scheme): string {
        return `! special
*.foreground:   ${ scheme.foreground }
*.background:   ${ scheme.background }
*.cursorColor:  ${ scheme.foreground }

! black
*.color0:       ${ scheme.color[0] }
*.color8:       ${ scheme.color[8] }

! red
*.color1:       ${ scheme.color[1] }
*.color9:       ${ scheme.color[9] }

! green
*.color2:       ${ scheme.color[2] }
*.color10:      ${ scheme.color[10] }

! yellow
*.color3:       ${ scheme.color[3] }
*.color11:      ${ scheme.color[11] }

! blue
*.color4:       ${ scheme.color[4] }
*.color12:      ${ scheme.color[12] }

! magenta
*.color5:       ${ scheme.color[5] }
*.color13:      ${ scheme.color[13] }

! cyan
*.color6:       ${ scheme.color[6] }
*.color14:      ${ scheme.color[14] }

! white
*.color7:       ${ scheme.color[7] }
*.color15:      ${ scheme.color[15] }`
    }
}
