import { Scheme } from '../schemeReducer';

export default {
    name: 'vconsole.conf',
    value: 'vconsole',
    extension: 'conf',
    formatter(scheme: Scheme): string {
        const colors = scheme.color.map(color => color.slice(1));

        return `COLOR_0=${ colors[0] }
COLOR_1=${ colors[1] }
COLOR_2=${ colors[2] }
COLOR_3=${ colors[3] }
COLOR_4=${ colors[4] }
COLOR_5=${ colors[5] }
COLOR_6=${ colors[6] }
COLOR_7=${ colors[7] }
COLOR_8=${ colors[8] }
COLOR_9=${ colors[9] }
COLOR_10=${ colors[10] }
COLOR_11=${ colors[11] }
COLOR_12=${ colors[12] }
COLOR_13=${ colors[13] }
COLOR_14=${ colors[14] }
COLOR_15=${ colors[15] }`;
    }
}
