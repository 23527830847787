import { Scheme } from '../schemeReducer';

export default {
    name: 'Chrome Secure Shell',
    value: 'chrome-secure-shell',
    extension: 'txt',
    formatter(scheme: Scheme): string {
        return `"background-color": "${ scheme.background }",
"foreground-color": "${ scheme.foreground }",
"cursor-color":     "${ scheme.foreground }",
"color-palette-overrides": ["${ scheme.color[0] }","${ scheme.color[1] }","${ scheme.color[2] }","${ scheme.color[3] }","${ scheme.color[4] }","${ scheme.color[5] }","${ scheme.color[6] }","${ scheme.color[7] }","${ scheme.color[8] }","${ scheme.color[9] }","${ scheme.color[10] }","${ scheme.color[11] }","${ scheme.color[12] }","${ scheme.color[13] }","${ scheme.color[14] }","${ scheme.color[15] }"]`
    }
}
