import { Scheme } from '../schemeReducer';
import alacritty from './alacritty';
import chromeSecureShell from './chrome-secure-shell';
import guake from './guake';
import hyper from './hyper';
import iterm2 from './iterm-2';
import json from './json';
import konsole from './konsole';
import kitty from './kitty';
import lxterminal from './lxterminal';
import mintty from './mintty';
import putty from './putty';
import terminalizer from './terminalizer';
import terminator from './terminator';
import termite from './termite';
import tilix from './tilix';
import vconsole from './vconsole';
import visualStudioCode from './visual-studio-code';
import windowsTerminal from './windows-terminal';
import xfceTerminal from './xfce-terminal';
import xresources from './xresources';
import xshell from './xshell';

export interface ExportFormat {
    name: string;
    value: string;
    extension: string;

    formatter(scheme: Scheme): string;
}

export const exportFormats: ExportFormat[] = [
    alacritty,
    chromeSecureShell,
    guake,
    hyper,
    iterm2,
    json,
    konsole,
    kitty,
    lxterminal,
    mintty,
    putty,
    terminalizer,
    terminator,
    termite,
    tilix,
    vconsole,
    visualStudioCode,
    windowsTerminal,
    xfceTerminal,
    xresources,
    xshell,
];
