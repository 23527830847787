import { Scheme } from '../schemeReducer';
import { hexToRgbCsv } from '../utils/colors';

export default {
    name: 'Konsole',
    value: 'konsole',
    extension: 'colorscheme',
    formatter(scheme: Scheme): string {
        const background = hexToRgbCsv(scheme.background);
        const foreground = hexToRgbCsv(scheme.foreground);
        const colors = scheme.color.map(color => hexToRgbCsv(color));

        return `# --- special scheme.colors ---

[Background]
Color=${ background }

[BackgroundIntense]
Color=${ background }

[Foreground]
Color=${ foreground }

[ForegroundIntense]
Color=${ foreground }
Bold=true

# --- standard scheme.colors ---

[Color0]
Color=${ colors[0] }

[Color0Intense]
Color=${ colors[8] }

[Color1]
Color=${ colors[1] }

[Color1Intense]
Color=${ colors[9] }

[Color2]
Color=${ colors[2] }

[Color2Intense]
Color=${ colors[10] }

[Color3]
Color=${ colors[3] }

[Color3Intense]
Color=${ colors[11] }

[Color4]
Color=${ colors[4] }

[Color4Intense]
Color=${ colors[12] }

[Color5]
Color=${ colors[5] }

[Color5Intense]
Color=${ colors[13] }

[Color6]
Color=${ colors[6] }

[Color6Intense]
Color=${ colors[14] }

[Color7]
Color=${ colors[7] }

[Color7Intense]
Color=${ colors[15] }

# --- general options ---

[General]
Description=terminal.love
Opacity=1
Wallpaper=`
    }
}
