import * as React from 'react';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className: string;
}

function Section({ children, className }: Props) {
    const classes = classNames({
        [className]: true,
        'flex flex-col bg-gray-900 p-4 mb-4 md:last:mb-0': true
    });

    return (
        <div className={ classes }>
            { children }
        </div>
    );
}

export default Section;
