import * as React from 'react';
import { connect } from 'react-redux';
import hsluv from 'hsluv';
import { Scheme, setScheme } from './schemeReducer';
import Title from './Title';
import Section from './Section';
import Button from './Button';

const random = (a = 1, b = 0) => {
    const lower = Math.ceil(Math.min(a, b));
    const upper = Math.floor(Math.max(a, b));
    return Math.floor(lower + Math.random() * (upper - lower + 1))
};

function generateRandomColors(): Scheme {
    // 6 hues to pick from
    const baseHue = random(0, 360);

    const hues = [0, 60, 120, 180, 240, 300].map(offset => {
        return (baseHue + offset) % 360;
    });

    //  8 shades of low-saturated color
    const baseSaturation = random(5, 40);
    const baseLightness = random(0, 10);
    const rangeLightness = 90 - baseLightness;

    // 8 random shades
    const minSat = random(30, 70);
    const maxSat = minSat + 30;
    const minLight = random(50, 70);
    const maxLight = minLight + 20;

    let colors = [];

    for (let i = 0; i < 8; i++) {
        colors[i] = hsluv.hsluvToHex([
            hues[0],
            baseSaturation,
            baseLightness + (rangeLightness * Math.pow(i / 7, 1.5))
        ]);
    }

    for (let i = 8; i < 16; i++) {
        colors[i] = hsluv.hsluvToHex([
            hues[random(0, hues.length - 1)],
            random(minSat, maxSat),
            random(minLight, maxLight)
        ]);
    }

    return {
        author: 'TODO',
        name: 'TODO',
        background: hsluv.hsluvToHex([
            hues[0],
            baseSaturation,
            baseLightness / 2
        ]),
        color: colors,
        foreground: hsluv.hsluvToHex([
            hues[0],
            baseSaturation,
            rangeLightness
        ])
    };
}

interface Props {
    onGenerateScheme: typeof setScheme;
}

function Randomizer({ onGenerateScheme }: Props) {
    const onClick = () => {
        onGenerateScheme({
            key: 'random',
            scheme: generateRandomColors()
        });
    }

    return (
        <Section className="randomizer">
            <Title>Randomizer</Title>

            <p>
                Randomly generate a color scheme, using the <a href="https://www.hsluv.org/syntax/" target="_blank" rel="noreferrer noopener">HSLuv color space</a>.
            </p>

            <Button className="mt-4" onClick={ onClick }>
                Generate
            </Button>
        </Section>
    );
}

const mapDispatchToProps = ({
    onGenerateScheme: setScheme
})

export default connect(null, mapDispatchToProps)(Randomizer);
