import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from './store';
import { Scheme, selectColor } from './schemeReducer';
import Title from './Title';
import Section from './Section';
import { isLight } from './utils/colors';

interface Props {
    scheme: Scheme;

    onClickColor: typeof selectColor;
}

interface SchemeColorProps {
    name: string;
    color: string;

    onClick: typeof selectColor;
}

function SchemeColor({ color, name, onClick }: SchemeColorProps) {
    let textColor;
    if (isLight(color)) {
        textColor = '#1A202C';
    } else {
        textColor = '#F7FAFC';
    }

    return (
        <div className="w-1/2 text-center even:pl-1 odd:pr-1 pb-2" onClick={ () => onClick(color) } style={ { color: textColor } }>
            <div className="py-3" style={ { backgroundColor: color } }>
                <div className="text-xl my-1">
                    { name }
                </div>

                <div className="text-sm">
                    { color }
                </div>
            </div>
        </div>
    );
}

const colorNames = ['bg', 'fg', 0, 8, 1, 9, 2, 10, 3, 11, 4, 12, 5, 13, 6, 14, 7, 15];

class SchemeColors extends React.Component<Props> {
    render() {
        const { onClickColor, scheme } = this.props;

        const colors = colorNames.map(name => {
            if (name === 'bg') {
                return <SchemeColor key="background" name="BG" color={ scheme.background } onClick={ onClickColor } />;
            }

            if (name === 'fg') {
                return <SchemeColor key="foreground" name="FG" color={ scheme.foreground } onClick={ onClickColor } />;
            }

            return <SchemeColor key={ name } name={ name.toString() } color={ scheme.color[name as number] }
                                onClick={ onClickColor } />
        });

        return (
            <Section className="editor flex-grow">
                <Title>Scheme Colors</Title>

                <div className="editor-colors flex flex-wrap">
                    { colors }
                </div>
            </Section>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    scheme: state.scheme.selectedScheme
});

const mapDispatchToProps = ({
    onClickColor: selectColor
});

export default connect(mapStateToProps, mapDispatchToProps)(SchemeColors);
