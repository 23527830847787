import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import schemes from './schemes/index.json';
import { setScheme } from './schemeReducer';
import Title from './Title';
import { RootState } from './store';
import Section from './Section';

interface Props {
    selectedSchemeKey: string;

    onSelectScheme: typeof setScheme;
}

function SchemeBrowser({ onSelectScheme, selectedSchemeKey }: Props) {
    const onChange = (scheme: string) => {
        onSelectScheme({
            key: scheme,
            scheme: require((`./schemes/${ scheme }`))
        });
    };

    const theSchemes = schemes.map(scheme => {
        const classes = classNames({
            'px-4 py-4': true,
            'bg-gray-300 text-gray-900': scheme === selectedSchemeKey
        });

        return (
            <div className={ classes } key={ scheme } onClick={ () => onChange(scheme) }>
                { scheme }
            </div>
        )
    });

    return (
        <Section className="scheme-browser overflow-hidden h-64 md:h-full">
            <Title>Scheme Browser</Title>

            <div className="flex-grow border overflow-scroll mt-2 text-white bg-gray-800">
                { theSchemes }
            </div>
        </Section>
    );
}

const mapStateToProps = (state: RootState) => ({
    selectedSchemeKey: state.scheme.selectedSchemeKey
});

const mapDispatchToProps = ({
    onSelectScheme: setScheme
});

export default connect(mapStateToProps, mapDispatchToProps)(SchemeBrowser);
