import { configureStore } from '@reduxjs/toolkit';
import schemeReducer, { SchemeState } from './schemeReducer';

export type RootState = {
    scheme: SchemeState
}

const rootReducer = {
    scheme: schemeReducer
};

export default configureStore({
    reducer: rootReducer
});
