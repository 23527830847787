import { Scheme } from '../schemeReducer';

export default {
    name: 'Guake',
    value: 'guake',
    extension: 'sh',
    formatter(scheme: Scheme): string {
        return `#!/bin/sh

# Save this script into set_scheme.colors.sh, make this file executable and run it: 
# 
# $ chmod +x set_scheme.colors.sh 
# $ ./set_scheme.colors.sh 
# 
# Alternatively copy lines below directly into your shell. 

gconftool-2 -s -t string /apps/guake/style/background/color '${ scheme.background }'
gconftool-2 -s -t string /apps/guake/style/font/color '${ scheme.foreground }'
gconftool-2 -s -t string /apps/guake/style/font/palette '${ scheme.color[0] }:${ scheme.color[1] }:${ scheme.color[2] }:${ scheme.color[3] }:${ scheme.color[4] }:${ scheme.color[5] }:${ scheme.color[6] }:${ scheme.color[7] }:${ scheme.color[8] }:${ scheme.color[9] }:${ scheme.color[10] }:${ scheme.color[11] }:${ scheme.color[12] }:${ scheme.color[13] }:${ scheme.color[14] }:${ scheme.color[15] }'`
    }
}
