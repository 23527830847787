import { Scheme } from '../schemeReducer';

export default {
    name: 'Xfce Terminal',
    value: 'xfce-terminal',
    extension: 'terminalrc',
    formatter(scheme: Scheme): string {
        return `[Scheme]
Name=terminal.love
ColorCursor=${ scheme.foreground }
ColorForeground=${ scheme.foreground }
ColorBackground=${ scheme.background }
ColorPalette=${ scheme.color[0] };${ scheme.color[1] };${ scheme.color[2] };${ scheme.color[3] };${ scheme.color[4] };${ scheme.color[5] };${ scheme.color[6] };${ scheme.color[7] };${ scheme.color[8] };${ scheme.color[9] };${ scheme.color[10] };${ scheme.color[11] };${ scheme.color[12] };${ scheme.color[13] };${ scheme.color[14] };${ scheme.color[15] }`
    }
}
