import { Scheme } from '../schemeReducer';

export default {
    name: 'JSON',
    value: 'json',
    extension: 'json',
    formatter(scheme: Scheme): string {
        return JSON.stringify(scheme, null, 4);
    }
}
