import * as React from 'react';
import lolight from 'lolight';
import './SyntaxHighlighter.css';

interface Props {
    code: string;
}

export default function SyntaxHighlighter({ code }: Props) {
    const tokens = lolight.tok(code).map((token: string[], i: number) => {
        return (
            <span className={ `ll-${ token[0] }` } key={ i.toString() }>
                { token[1] }
            </span>
        )
    });

    return (
        <div className="min-h-full bg-gray-800 overflow-scroll px-4 py-3">
            <div className="font-mono h-full w-full text-gray-100 leading-loose whitespace-pre">
                { tokens }
            </div>
        </div>
    )
}
