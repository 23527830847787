import * as React from 'react';

interface Props {
    children: React.ReactNode;
    href: string;
}

function Link({ children, href }: Props) {
    return (
        <a href={ href } target="_blank" rel="noreferrer noopener">{ children }</a>
    );
}

export default Link;
