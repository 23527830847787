import { Scheme } from '../schemeReducer';

export default {
    name: 'Tilix/Terminix',
    value: 'tilix',
    extension: 'json',
    formatter(scheme: Scheme): string {
        return `{
    "name": "terminal.love",
    "use-theme-colors": false,
    "background-color": "${ scheme.background }",
    "foreground-color": "${ scheme.foreground }",
    "palette": [
        "${ scheme.color[0] }",
        "${ scheme.color[1] }",
        "${ scheme.color[2] }",
        "${ scheme.color[3] }",
        "${ scheme.color[4] }",
        "${ scheme.color[5] }",
        "${ scheme.color[6] }",
        "${ scheme.color[7] }",
        "${ scheme.color[8] }",
        "${ scheme.color[9] }",
        "${ scheme.color[10] }",
        "${ scheme.color[11] }",
        "${ scheme.color[12] }",
        "${ scheme.color[13] }",
        "${ scheme.color[14] }",
        "${ scheme.color[15] }"
    ],
}`;
    }
}
