import * as React from 'react';
import Title from './Title';
import Section from './Section';
import Link from './Link';

function About() {
    return (
        <Section className="about">
            <Title>About</Title>

            <p>Heavily inspired by <strong>terminal.sexy</strong>, and uses the awesome <Link href="https://github.com/hsluv/hsluv">hsluv</Link> and <Link href="https://larsjung.de/lolight/">lolight</Link> libraries to generate pretty terminal color schemes.</p>
        </Section>
    );
}

export default About;
