import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from './store';
import { Scheme, selectExportFormat } from './schemeReducer';
import SyntaxHighlighter from './SyntaxHighlighter';
import { ExportFormat, exportFormats } from './formats';
import Title from './Title';
import Section from './Section';
import Button from './Button';

interface Props {
    selectedFormat: ExportFormat;
    selectedScheme: Scheme;

    onSelectFormat: typeof selectExportFormat;
}

function Export({ onSelectFormat, selectedFormat, selectedScheme }: Props) {
    const options = exportFormats.sort((a, b) => a.name.localeCompare(b.name)).map(format => {
        return <option key={ format.value } value={ format.value }>{ format.name }</option>
    });

    const downloadScheme = (content: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', `scheme.${ selectedFormat.extension }`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    return (
        <Section className="export h-1/2">
            <Title>Export</Title>

            <div className="flex flex-row">
                <select className="flex-grow bg-gray-300 text-gray-900 p-2"
                        onChange={ event => onSelectFormat(event.currentTarget.value) }
                        value={ selectedFormat.value }>
                    { options }
                </select>

                <Button className="px-6 ml-4" onClick={ () => downloadScheme(selectedFormat.formatter(selectedScheme)) }>
                    Download
                </Button>
            </div>

            <div className="export-output flex-grow border overflow-scroll mt-4">
                <SyntaxHighlighter code={ selectedFormat.formatter(selectedScheme) } />
            </div>
        </Section>
    );
}

const mapStateToProps = (state: RootState) => ({
    selectedScheme: state.scheme.selectedScheme,
    selectedFormat: exportFormats.find(format => format.value === state.scheme.selectedExportFormat) || exportFormats[0]
});

const mapDispatchToProps = ({
    onSelectFormat: selectExportFormat
});

export default connect(mapStateToProps, mapDispatchToProps)(Export);
