import * as React from 'react';

interface Props {
    children: React.ReactNode;
}

function Title({ children }: Props) {
    return (
        <h2 className="font-bold mb-2 text-base">
            { children }
        </h2>
    );
}

export default Title;
