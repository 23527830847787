import { Scheme } from '../schemeReducer';

export default {
    name: 'Visual Studio Code',
    value: 'visual-studio-code',
    extension: 'json',
    formatter(scheme: Scheme): string {
        const background = scheme.background.slice(1);
        const foreground = scheme.foreground.slice(1);
        const colors = scheme.color.map(color => color.slice(1));

        return `{
  "workbench.colorCustomizations": {
    "terminal.ansiBlack": "${ colors[0] }",
    "terminal.ansiBlue": "${ colors[4] }",
    "terminal.ansiBrightBlack": "${ colors[8] }",
    "terminal.ansiBrightBlue": "${ colors[12] }",
    "terminal.ansiBrightCyan": "${ colors[14] }",
    "terminal.ansiBrightGreen": "${ colors[10] }",
    "terminal.ansiBrightMagenta": "${ colors[13] }",
    "terminal.ansiBrightRed": "${ colors[9] }",
    "terminal.ansiBrightWhite": "${ colors[15] }",
    "terminal.ansiBrightYellow": "${ colors[11] }",
    "terminal.ansiCyan": "${ colors[6] }",
    "terminal.ansiGreen": "${ colors[2] }",
    "terminal.ansiMagenta": "${ colors[5] }",
    "terminal.ansiRed": "${ colors[1] }",
    "terminal.ansiWhite": "${ colors[7] }",
    "terminal.ansiYellow": "${ colors[3] }",
    "terminal.background": "${ background }",
    "terminal.foreground": "${ foreground }"
  }
}`;
    }
}
