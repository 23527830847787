import { Scheme } from '../schemeReducer';

export default {
    name: 'Alacritty',
    value: 'alacritty',
    extension: 'yaml',
    formatter(scheme: Scheme): string {
        return `colors:
  # Default colors
  primary:
    background: '${ scheme.background }'
    foreground: '${ scheme.foreground }'

  # Normal colors
  normal:
    black:   '${ scheme.color[0] }'
    red:     '${ scheme.color[1] }'
    green:   '${ scheme.color[2] }'
    yellow:  '${ scheme.color[3] }'
    blue:    '${ scheme.color[4] }'
    magenta: '${ scheme.color[5] }'
    cyan:    '${ scheme.color[6] }'
    white:   '${ scheme.color[7] }'

  # Bright colors
  bright:
    black:   '${ scheme.color[8] }'
    red:     '${ scheme.color[9] }'
    green:   '${ scheme.color[10] }'
    yellow:  '${ scheme.color[11] }'
    blue:    '${ scheme.color[12] }'
    magenta: '${ scheme.color[13] }'
    cyan:    '${ scheme.color[14] }'
    white:   '${ scheme.color[15] }'
`;
    }
}
