import { Scheme } from '../schemeReducer';
import { hexToRgb } from '../utils/colors';

export default {
    name: 'iTerm 2',
    value: 'iterm-2',
    extension: 'itermcolors',
    formatter(scheme: Scheme): string {
        const background = hexToRgb(scheme.background);
        const foreground = hexToRgb(scheme.foreground);
        const colors = scheme.color.map(color => hexToRgb(color));

        return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
<dict>
\t<key>Ansi 0 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[0].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[0].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[0].r }</real>
\t</dict>
\t<key>Ansi 1 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[1].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[1].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[1].r }</real>
\t</dict>
\t<key>Ansi 10 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[10].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[10].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[10].r }</real>
\t</dict>
\t<key>Ansi 11 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[11].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[11].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[11].r }</real>
\t</dict>
\t<key>Ansi 12 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[12].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[12].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[12].r }</real>
\t</dict>
\t<key>Ansi 13 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[13].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[13].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[13].r }</real>
\t</dict>
\t<key>Ansi 14 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[14].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[14].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[14].r }</real>
\t</dict>
\t<key>Ansi 15 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[15].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[15].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[15].r }</real>
\t</dict>
\t<key>Ansi 2 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[2].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[2].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[2].r }</real>
\t</dict>
\t<key>Ansi 3 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[3].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[3].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[3].r }</real>
\t</dict>
\t<key>Ansi 4 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[4].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[4].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[4].r }</real>
\t</dict>
\t<key>Ansi 5 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[5].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[5].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[5].r }</real>
\t</dict>
\t<key>Ansi 6 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[6].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[6].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[6].r }</real>
\t</dict>
\t<key>Ansi 7 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[7].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[7].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[7].r }</real>
\t</dict>
\t<key>Ansi 8 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[8].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[8].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[8].r }</real>
\t</dict>
\t<key>Ansi 9 Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ colors[9].b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ colors[9].g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ colors[9].r }</real>
\t</dict>
\t<key>Background Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ background.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ background.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ background.r }</real>
\t</dict>
\t<key>Bold Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ background.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ background.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ background.r }</real>
\t</dict>
\t<key>Cursor Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ foreground.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ foreground.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ foreground.r }</real>
\t</dict>
\t<key>Cursor Text Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ background.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ background.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ background.r }</real>
\t</dict>
\t<key>Foreground Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ foreground.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ foreground.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ foreground.r }</real>
\t</dict>
\t<key>Selected Text Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ foreground.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ foreground.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ foreground.r }</real>
\t</dict>
\t<key>Selection Color</key>
\t<dict>
\t\t<key>Color Space</key>
\t\t<string>sRGB</string>
\t\t<key>Blue Component</key>
\t\t<real>${ background.b }</real>
\t\t<key>Green Component</key>
\t\t<real>${ background.g }</real>
\t\t<key>Red Component</key>
\t\t<real>${ background.r }</real>
\t</dict>
</dict>
</plist>
`
    }
}
