import { Scheme } from '../schemeReducer';

export default {
    name: 'Hyper',
    value: 'hyper',
    extension: 'js',
    formatter(scheme: Scheme): string {
        return `exports.decorateConfig = config => {
    return Object.assign({}, config, {
        foregroundColor: '${ scheme.foreground }',
        backgroundColor: '${ scheme.background }',
        cursorColor: '${ scheme.foreground }',
        colors: {
          black:        '${ scheme.color[0] }',
          red:          '${ scheme.color[1] }',
          green:        '${ scheme.color[2] }',
          yellow:       '${ scheme.color[3] }',
          blue:         '${ scheme.color[4] }',
          magenta:      '${ scheme.color[5] }',
          cyan:         '${ scheme.color[6] }',
          white:        '${ scheme.color[7] }',
          lightBlack:   '${ scheme.color[8] }',
          lightRed:     '${ scheme.color[9] }',
          lightGreen:   '${ scheme.color[10] }',
          lightYellow:  '${ scheme.color[11] }',
          lightBlue:    '${ scheme.color[12] }',
          lightMagenta: '${ scheme.color[13] }',
          lightCyan:    '${ scheme.color[14] }',
          lightWhite:   '${ scheme.color[15] }'
        }
    });
};`;
    }
};
