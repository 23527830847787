import { Scheme } from '../schemeReducer';

export default {
    name: 'Xshell',
    value: 'xshell',
    extension: 'xcs',
    formatter(scheme: Scheme): string {
        const background = scheme.background.slice(1);
        const foreground = scheme.foreground.slice(1);
        const colors = scheme.color.map(color => color.slice(1));

        return `[terminal.love]
text=${ foreground }
text(bold)=${ foreground }
background=${ background }
black=${ colors[0] }
black(bold)=${ colors[8] }
red=${ colors[1] }
red(bold)=${ colors[9] }
green=${ colors[2] }
green(bold)=${ colors[10] }
yellow=${ colors[3] }
yellow(bold)=${ colors[11] }
blue=${ colors[4] }
blue(bold)=${ colors[12] }
magenta=${ colors[5] }
magenta(bold)=${ colors[13] }
cyan=${ colors[6] }
cyan(bold)=${ colors[14] }
white=${ colors[7] }
white(bold)=${ colors[15] }
[Names]
name0=terminal.love
count=1`
    }
}
