import * as React from 'react';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Column({ children, className }: Props) {
    const classes = classNames({
        [className || '']: true,
        'flex flex-col mr-0 md:mr-4 md:last:mr-0': true
    });

    return (
        <div className={ classes }>
            { children }
        </div>
    );
}
