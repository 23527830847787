import React from 'react';
import SchemeColors from './SchemeColors';
import Export from './Export';
import Randomizer from './Randomizer';
import About from './About';
import SchemeBrowser from './SchemeBrowser';
import Example from './Example';
import Column from './Column';
import Brand from './Brand';

function App() {
    return (
        <div className="app flex flex-col md:flex-row h-full bg-gray-800 text-white">
            <Column className="md:w-1/4">
                <Brand />
                <About />
                <Randomizer />
                <SchemeBrowser />
            </Column>

            <Column className="md:w-1/4">
                <SchemeColors />
            </Column>

            <Column className="md:w-1/2">
                <Export />
                <Example />
            </Column>
        </div>
    );
}

export default App;
