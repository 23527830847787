import { Scheme } from '../schemeReducer';
import { hexToRgbCsv } from '../utils/colors';

export default {
    name: 'MinTTY',
    value: 'mintty',
    extension: 'minttyrc',
    formatter(scheme: Scheme): string {
        const background = hexToRgbCsv(scheme.background);
        const foreground = hexToRgbCsv(scheme.foreground);
        const colors = scheme.color.map(color => hexToRgbCsv(color));

        return `BackgroundColour=${ background }
ForegroundColour=${ foreground }
CursorColour=${ foreground }
Black=${ colors[0] }
BoldBlack=${ colors[8] }
Red=${ colors[1] }
BoldRed=${ colors[9] }
Green=${ colors[2] }
BoldGreen=${ colors[10] }
Yellow=${ colors[3] }
BoldYellow=${ colors[11] }
Blue=${ colors[4] }
BoldBlue=${ colors[12] }
Magenta=${ colors[5] }
BoldMagenta=${ colors[13] }
Cyan=${ colors[6] }
BoldCyan=${ colors[14] }
White=${ colors[7] }
BoldWhite=${ colors[15] }`
    }
}
