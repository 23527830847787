// Taken from: https://stackoverflow.com/a/5624139/1050908
export function hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const fullHex = hex.replace(shorthandRegex, function (m, r: string, g: string, b: string) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);

    return result === null
        ? { r: 0, g: 0, b: 0 }
        : {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };
}

export function hexToRgbCsv(hex: string) {
    const rgb = hexToRgb(hex);

    return `${ rgb.r },${ rgb.g },${ rgb.b }`;
}

// Extracted from https://github.com/Qix-/color/blob/e1e735ed73dec769396678af3f912600a4c1e6f6/index.js#L287
export function isDark(hex: string): boolean {
    const rgb = hexToRgb(hex);
    if (!rgb) {
        return false;
    }

    const yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    return yiq < 128;
}

export function isLight(hex: string): boolean {
    return !isDark(hex);
}
